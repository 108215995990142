<template>
  <LayoutContainer
    class="relative flex h-full select-none flex-col bg-white"
    :safe="false"
  >
    <div class="relative grow">
      <div class="absolute inset-0">
        <NuxtPage />
      </div>
    </div>

    <ZipupNavbar
      class="z-30 flex flex-none items-end"
      :safe="true"
    ></ZipupNavbar>

    <template v-if="loading">
      <DialogLoading />
    </template>
  </LayoutContainer>
</template>
<script lang="ts">
import { useLoadingStore } from '~/store/loading'

export default defineComponent({
  setup() {
    const loadingStore = useLoadingStore()

    const loading = computed(() => loadingStore.opened)

    return {
      loading,
    }
  },
})
</script>
